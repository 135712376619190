import React from "react"
import Layout from "../components/layout"
import RestaurangSinglePage from "../components/restaurang/RestaurangSinglePage"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"

export default function RestaurangPageEn() {
  const data = useStaticQuery(graphql`
    query {
      wpPage(databaseId: { eq: 814 }) {
        id
        title
        restauranginfo {
          textIngressRestaurang
          rubrikSidhuvudRestaurang
          rubrikIngressRestaurang
          lunchRestaurang {
            beskrivningLunchRestaurang
            rubrikDagensLunch
            extrainfoLunch
            rubrikLunchRestaurang
            veckomenyDagensLunch {
              rattLunch
              veckodagLunch
            }
            menySomPdf {
              mediaItemUrl
            }
          }
          teatermenyRestaurang {
            beskrivningTeatermeny
            rubrikTeatermeny
          }
          middagResturang {
            beskrivningMiddagRestaurang
            rubrikMiddagRestaurang
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)
  const page = data.wpPage
  if (!page) return null
  return (
    <Layout lang="en">
      <SEO title="Restaurant" />
      <>
        <RestaurangSinglePage lang="en" page={page} />
      </>
    </Layout>
  )
}
